<template>
    <div class="int">
        <div>
            <img class="ilustracijaInt" id="istKapija" src="../../assets/ilustrations/istocneKapijeR.png" alt="istocna Kapija">
           <!-- <img class="ilustracijaInt" id="pobednik" src="../../assets/ilustrations/pobednikR.png" alt="pobednik">-->
        </div>
        <div class="intContent">
            <h2 class="intTitle">Internacionala</h2>
            <div>
                <p class="intText">
                    Udruženje studenata elektrotehnike Evrope (Electrical Engineering STudents’ European assoCiation – Istek) je apolitična, nevladina i neprofitna organizacija koja okuplja studente elektrotehnike i računarstva širom Evrope. Možemo čak i reći da je ovo udruženje najveći međunarodni projekat koji povezuje studente elektrotehnike. 
                </p>
                <p class="intText">
                    Udruženje je osnovano 1986. Godine u Ajndhovenu, Holandija. Trenutno administrativno središte organizacije nalazi se u Delftu, Holandija. Na međunarodnom nivou, odluke o organizaciji donose članovi Internacionalnog upravnog odbora, koji danas čine predsednik, blagajnik, koordinator za unutrašnje poslove, koordinator za spoljne poslove i sekretar. Mandat traje godinu dana, odnosno svakog Prolećnog kongresa bira se novi Internacionalni upravni odbor. Njihov zadatak je da održe povezanost i koheziju lokalnih komiteta, pomažu njihovom napretku, kao i da savetuju i ohrabruju mlade ili potencijalne komitete. Na Internacionalnom nivou postoje i različiti timovi koji pomažu upravnom odboru pri radu Udruženja, kao i timovi koji se tiču internacionalnih projekata. 
                </p>
                <p class="intText">
                    Na lokalnom nivou organizaciju čine lokalni komiteti, od kojih svaki ima sopstveni upravni odbor, način organizovanja i članove. Članove čine studenti elektrotehnike i računarstva iz 46 lokalna komiteta i 23 zemlje Evrope. Udruženje se iz godine u godinu širi prijemom novih Komiteta na Kongresu Udruženja. Kako bi postali član Udruženja, komiteti moraju da organizuju bar dva tipa dogadjaja: radionice i razmene. Radionice su događaji akademskog karaktera gde se studenti upoznaju sa aktuelnim dešavanjima iz oblasti elektrotehnike i računarstva. Raznim predavanjima, posetama kompanijama, strani studenti se upoznaju sa tehničkim razvojem te države. Razmene su događaji kulturnog i socijalnog karaktera, putem kojih se studenti upoznaju sa kulturama drugih naroda, stvaraju nova poznanstva, usavsšavaju znanje stranih jezika i time podižu nivo svojih ličnih veština. 
                </p>
                <p class="intText">
                    Cilj organizacije je širenje, razvoj i razmena ideja, kao i saradnja studenata sa kompanijama i univerzitetima u Evropi. Rad i delovanje Udruženja, oslanja se na veštine i entuzijazam njenih članova, volontera, kao i na njihovu želju za napredovanjem i ličnim usavršavanjem. Vizija Udruženja jeste ujedinjenje studenata elektrotehnike i računarstva iz čitave Evrope, kako bi dostigli svoj pun potencijal i u ličnom i u akademskom smislu.
                </p>
            </div>
            <div class="intPaneli"><!--
                <SmallCard title="Naslov neke vesti!" text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                    sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                    At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no" 
                    more="Pročitaj više" link="https://www.google.com" imageName="conference.png"/>
                <SmallCard title="Naslov neke vesti!" text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                    sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                    At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no" 
                    more="Pročitaj više" link="https://www.google.com" imageName="conference.png"/>
                <SmallCard title="Naslov neke vesti!" text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                    sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                    At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no" 
                    more="Pročitaj više" link="https://www.google.com" imageName="conference.png"/>
                <SmallCard title="Naslov neke vesti!" text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                    sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                    At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no" 
                    more="Pročitaj više" link="https://www.google.com" imageName="conference.png"/>    
                    -->
            </div>
        </div>
    </div>
</template>

<script>

import SmallCard from "../SmallCard.vue";

export default {
    name: "Internacional",
    components: { 
        SmallCard
    }
}

</script>

<style>

    .intContent{
        width: 85%;
        margin: 60px 7.5% 40px 7.5%;
    }

    .intTitle{
        margin-bottom: 40px;
        font-size: 45px;
        color: #E52A30;
    }

    .intText{
        line-height: 1.7;
    }

    .ilustracijaInt{
        position: absolute; 
    }

    #istKapija{
        right: 5%;
        width: 60%;
    }

    #pobednik{
        margin-top: 600px;
        right: 34%;
        width: 32%;
    }

    .intPaneli{
        margin: 80px auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 80px;
        height: 100px;
    }


</style>